<template>
  <div class="card">
    <h3 class="card-title">LISTAR LIQUIDACIONES</h3>
    <div v-if="liquidaciones">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="liquidaciones"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="showLiquidacion"
      ></table-list>
    </div>
  </div>
</template>
<script>
import TableList from "@/components/elements/TableList";
import {LiquidacionesServices} from '../services/LiquidacionesServices'
export default {
  name:'ListarLiquidaciones',
  components:{
    TableList
  },
  data(){
    return{
      liquidaciones: null,
      headers: LiquidacionesServices.headers(),
      body: LiquidacionesServices.body(),
      actionButton: {
        text: "Nueva liquidación",
        action: () => {
          this.$router.push({name:'NuevaLiquidacion'})
        },
      },

    }
  },
  mounted(){
    LiquidacionesServices.api.getAll().then((liquidaciones) => (this.liquidaciones = liquidaciones));
  },
  methods:{
    showLiquidacion(liquidacion){
      this.$router.push({name: 'VerLiquidacion', params:{id : liquidacion.id}})
    }
  }
}
</script>